import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../layouts/layout'
import BlueHeader from '../../components/UI/Header/BlueHeader'
import FaqComponent from '../../components/ForFaqPage/faqComponent'
import Seo from '../../components/seo'
import { help } from '../../support/structuredDataConfig/marketplace/help'
import { CONTACT } from '../../support'

const FaqPage = ({ path }) => {

  const data = useStaticQuery(graphql`
    query FaqComponentQuery {
      allContentfulComponentFaQs
      (filter: { category: {in: [ "Personal Loan", "General", "Credit Score", "Investor"]}})
      {
        nodes {
          category
          question
          answer {
            childMarkdownRemark {
              html
            }
          }
          isOnlyForHelp
        }
      }
    }
  `)

const faqs = data.allContentfulComponentFaQs.nodes.filter(faq => faq.isOnlyForHelp) //filter out faqs that are only for help page

  return (
    <Layout hasGetYourRate={false}>
      <Seo 
        title="Frequently Asked Questions"
        description="Helpful answers to your questions about personal loans and rates, credit scores, investing and more."
        path={path}
        structuredData={help(faqs)}
      />
      <BlueHeader headerText={"Frequently Asked Questions"}>
        <p>Can’t find what you’re looking for? Contact us at <a className='alink' href={`mailto:${CONTACT.EMAIL.CUSTOMER_SERVICE}`}>{CONTACT.EMAIL.CUSTOMER_SERVICE}</a></p>
      </BlueHeader>
      <FaqComponent faqs={faqs} />
    </Layout>

  )
}

export default FaqPage
